import { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useIsMediaAvailable } from './useIsMediaAvailable'

const usePublishedMedia = () => {
	const publishedMedia = useSelector((s) => s.event.publishedMedia, shallowEqual)
	const [media, setMedia] = useState(publishedMedia)
	const { isAvailable, res } = useIsMediaAvailable(publishedMedia)
	useEffect(() => {
		if (publishedMedia && isAvailable) {
			setMedia(publishedMedia)
		} else {
			if (publishedMedia) {
				setMedia({
					...publishedMedia,
					inGlacierStorage: publishedMedia.hasOwnProperty('inGlacierStorage')
						? publishedMedia.inGlacierStorage
						: true,
				})
			} else {
				setMedia(null)
			}
		}
	}, [publishedMedia, isAvailable])
	// todo: something is wrong in the redux store and return an array as default when there is no published media but it should be null... Just a hack....
	return Array.isArray(publishedMedia) ? null : media
}
export default usePublishedMedia
