import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import Card from '../../../../atoms/Card'
import { chakra, Image, Flex, Button, Text, HStack, LightMode } from '@chakra-ui/react'
import { ArrowForwardRounded } from '../../../../atoms/Icon'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'

// import useUserLocation from '../../../../../hooks/useUserLocation'

export const VideoBook = () => {
	const navigate = useNavigate()
	const { event, isOwner } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const { pathname, search } = useLocation()
	// const userLocation = useUserLocation()

	/**
	 * Build target for redirection based on user role
	 * */
	let redirectTarget = `${handlers.public_keepsake_video_book}${search}`
	if (pathname.includes('/event') && isOwner) {
		redirectTarget = `${handlers.keepsake_video_book}${search}`
	}

	const handleRedirect = () => redirectToNextIfWindow(redirectTarget)

	const [image] = useState(`/assets/images/keepsakes/vidday-video-book-1.gif`)

	return (
		<Card
			onClick={handleRedirect}
			sx={{
				overflow: 'visible',
				minW: '255px',
				maxW: '255px',
				borderRadius: 'xl',
				mr: '0.5rem !important',
				mb: '0.5rem !important',
				ml: '0.5rem !important',
				_hover: {
					cursor: 'pointer',
				},
			}}>
			<Image key={image} src={image} width="255px" height="185px" borderTopRadius="xl" />

			<Flex justify="space-between" alignItems="center" w="full" px="1rem" h="80px">
				<Flex direction="column">
					<Text fontWeight="bold" variant="title">
						Video Book Keepsake
					</Text>
					<HStack>
						<Text color="var(--vidday-colors-link)" fontSize="1rem">
							$79.
							<chakra.small top="-4px" position="relative" fontSize="x-small">
								99
							</chakra.small>
						</Text>
						<Text color="#6C7A88" fontSize="1rem" textDecoration="line-through">
							$100
						</Text>
						{/* {userLocation?.country === 'US' && ( 
							<Text mb="1rem" fontWeight="bold"><small>Free Shipping</small></Text>
						)} */}
					</HStack>
				</Flex>
				<Flex>
					<LightMode>
						<Button aria-label="DVD Keepsake" size="md" variant="iconCta" onClick={handleRedirect}>
							<ArrowForwardRounded />
						</Button>
					</LightMode>
				</Flex>
			</Flex>
		</Card>
	)
}

export default VideoBook
